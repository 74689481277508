














import { defineComponent, ref, onBeforeMount } from '@vue/composition-api'
import axios from 'axios'

import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import auth from '@/composables/auth'
import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'my-link',
  components: {
    FeedbackMessage,
  },
  setup(props, ctx) {
    const { $logger, $route, $router } = ctx.root
    const { token } = $route.params
    const error = ref('')

    onBeforeMount(async () => {
      try {
        const res = await axios.post(`${getApiUrl()}/token`, null, {
          params: { Token: token },
        })

        if (res.status !== 200) {
          error.value = res.data?.message

          return
        }

        $router.push({
          name: 'MyPppSuccess',
        })
      } catch (err) {
        error.value = err.message || err.response?.data?.message
        $logger.error(err)
      }
    })

    return {
      auth,
      error,
      token,
    }
  },
})
